import { combineReducers } from 'redux';
import auth from './auth/reducer';
import general from './general/reducer';
import masterData from './masterData/reducer';
import right from './right/reducer';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createHashHistory } from 'history';
import freshService from './freshService/reducer';
import log from './logging/reducer';
import frequentlyAskedQuestions from './frequentlyAskedQuestions/reducer';
import paymentTransactions from './paymentTransaction/reducer';
import payRunExtraction from './payRunExtraction/reducer';
import batchTransactions from './batchTransactions/reducer';
import sync from './syncing/reducer';
import job from './job/reducer';
import pieceWorkTransaction from './piecework/reducer';
import rawClock from './rawClock/reducer';

export const history = createHashHistory();

export const createRootReducer = combineReducers({
    auth,
    general,
    right,
    masterData,
    freshService,
    log,
    frequentlyAskedQuestions,
    sync,
    paymentTransactions,
    batchTransactions,
    job,
    pieceWorkTransaction,
    rawClock,
    payRunExtraction
});

export const store = configureStore({
    reducer: createRootReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
        // immutableCheck: false
    }),
});

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;
