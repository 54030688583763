import { IUser } from '../../@types/model/auth/user/user';
import { getUserSelectedDepartmentsLocalStorage, getUserSelectedDivisionsLocalStorage } from '../localStorageService';

export default class UserHelper {
    public static getUserDivisionHeading = (
        user : IUser | undefined, 
    ) : string => {
        if (!user) return '';
        if (!user.divisionIds) return 'NO DIVISION SELECTED';
        const selectedDivisions = getUserSelectedDivisionsLocalStorage();
        
        const selectedDivisionCount = selectedDivisions.length;
     
        switch (selectedDivisionCount) {
            case 0:
                return 'NO DIVISION SELECTED';
            case 1:
                return selectedDivisions[0].label;
            case user.divisionIds.length:
                return 'ALL DIVISIONS';
            default:
                return 'MULTIPLE DIVISIONS';
        }
    };

    public static getUserDepartmentHeading = (
        user ?: IUser, 
    ) : string => {
        if (!user) return '';
        if (!user.departmentIds) return 'NO DEPARTMENT SELECTED';
        const selectedDivisions = getUserSelectedDivisionsLocalStorage();
        const selectedDepartments = getUserSelectedDepartmentsLocalStorage();

        const activeUserDepartments = user.userDepartments
            .filter(userDept => userDept.isActive && userDept.department.isActive)
            .map(userDept => userDept.department);

        const filteredDepartments = activeUserDepartments
            .filter(dept => selectedDivisions.map(div => div.value).includes(dept.subdivision?.divisionId ?? 0));

        const selectedDepartmentCount = selectedDepartments.length;
    
        switch (selectedDepartmentCount) {
            case 0:
                return 'NO DEPARTMENT SELECTED';
            case 1:
                return selectedDepartments[0].label;
            case filteredDepartments.length:
                return 'ALL DEPARTMENTS';
            default:
                return 'MULTIPLE DEPARTMENTS';
        }
    };
}