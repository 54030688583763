import { openDb, UpgradeDB } from 'idb';
import { IUserToken } from '../@types/model/auth/userToken/userToken';
import { IOptionType } from '../@types/model/optionType';
import {         
    ACTIVITY_TABLE_NAME,
    ACTIVITY_TYPE_TABLE_NAME,
    BLOCK_TABLE_NAME,
    CLOCKING_METHOD_TABLE_NAME,
    CLOCKING_SYSTEM_TABLE_NAME,
    COMMODITY_TABLE_NAME,
    CONTACT_US_TABLE_NAME,
    COUNTRY_TABLE_NAME,
    CROP_TABLE_NAME,
    DEPARTMENT_TABLE_NAME,
    DIVISION_TABLE_NAME,
    DIVISION_DAYS_OF_WEEK_TABLE_NAME,
    EMPLOYEE_TABLE_NAME,
    EMPLOYEE_RATE_TABLE_NAME,
    FIELD_TABLE_NAME,
    JOB_TYPE_TABLE_NAME,
    MINIMUM_WAGE_HISTORY_TABLE_NAME,
    NEWS_TABLE_NAME,
    PRODUCTION_UNIT_TABLE_NAME,
    PROJECT_TABLE_NAME,
    PUBLIC_HOLIDAY_TABLE_NAME,
    ROOT_STOCK_TABLE_NAME,
    SCAN_TYPE_TABLE_NAME,
    SUBDIVISION_TABLE_NAME,
    TARIF_CALCULATION_TABLE_NAME,
    TARIF_TYPE_TABLE_NAME,
    VARIETY_TABLE_NAME,
    NORM_TABLE_NAME,
    EMPLOYEE_SETUP_TABLE_NAME,
    COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME,
    PAY_RUN_TABLE_NAME,
    ACTIVITY_RATE_TABLE_NAME,
    COMPANY_REFERENCE_TABLE_NAME,
    BUSINESS_RULE_TABLE_NAME,
} from './masterDataSyncService';
import { IActivity } from '../@types/model/masterData/activity/activity';
import { IBlock } from '../@types/model/masterData/block/block';
import { IClockingMethod } from '../@types/model/masterData/clockingMethod/clockingMethod';
import { ICommodity } from '../@types/model/masterData/commodity/commodity';
import { IContactUs } from '../@types/model/masterData/contactUs/contactUs';
import { ICountry } from '../@types/model/masterData/country/country';
import { ICrop } from '../@types/model/masterData/crop/crop';
import { IDepartment } from '../@types/model/masterData/department/department';
import { IDivision } from '../@types/model/masterData/division/division';
import { IEmployee } from '../@types/model/masterData/employee/employee';
import { IEmployeeRate } from '../@types/model/masterData/employeeRate/employeeRate';
import { IField } from '../@types/model/masterData/field/field';
import { IJobType } from '../@types/model/masterData/jobType/jobType';
import { IMinimumWageHistory } from '../@types/model/masterData/minimumWageHistory/minimumWageHistory';
import { INews } from '../@types/model/masterData/news/news';
import { IProductionUnit } from '../@types/model/masterData/productionUnit/productionUnit';
import { IProject } from '../@types/model/masterData/project/project';
import { IPublicHoliday } from '../@types/model/masterData/publicHoliday/publicHoliday';
import { IRootStock } from '../@types/model/masterData/rootStock/rootStock';
import { IScanType } from '../@types/model/masterData/scanType/scanType';
import { ISubdivision } from '../@types/model/masterData/subdivision/subdivision';
import { ITarifCalculation } from '../@types/model/masterData/tarifCalculation/tarifCalculation';
import { ITarifType } from '../@types/model/masterData/tarifType/tarifType';
import { IVariety } from '../@types/model/masterData/variety/variety';
import { IClockingSystem } from '../@types/model/masterData/clockingSystem/clockingSystem';
import { IDivisionDayOfWeek } from '../@types/model/masterData/divisionDayOfWeek/divisionDayOfWeek';
import { INorm } from '../@types/model/masterData/norm/norm';
import { IActivityType } from '../@types/model/masterData/activityType/activityType';
import { IEmployeeSetup } from '../@types/model/masterData/employeeSetup/employeeSetup';
import { IPayRun } from '../@types/model/masterData/payRun/payRun';
import { processWizardIndexedDBStores} from './wizardService';
import { IPaymentTransaction } from '../@types/model/paymentTransactions/paymentTransaction';
import { IActivityRate } from '../@types/model/masterData/activityRate/activityRate';
import { ICompanyReference } from '../@types/model/masterData/companyReference/companyReference';
import { IBusinessRule } from '../@types/model/masterData/businessRule/businessRule';

const SESSION_NAME = 'zz2-e-wages-session';
const SESSION_KEY = 'zz2-e-wages-session-token';

const SELECTED_USER_DIVISIONS_KEY = 'ewages-selected-user-divisions';
const SELECTED_USER_DEPARTMENTS_KEY = 'ewages-selected-user-departments';

const MASTER_DATA_LAST_SYNC_DATE = 'ewages-master-data-last-sync-date';

const SELECTED_DATE_FORMAT = 'ewages-selected-user-date-format';

const SELECTED_PAYMENT_TRANSACTION = 'ewages-selected-payment-transaction';

// eslint-disable-next-line no-unused-vars
let sessionCallback : (userToken : IUserToken | null) => void;

export async function getLocalStorageSession() : Promise<IUserToken | null> {
    let session : IUserToken | null = null;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        session = await getSessionIndexedDB();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        session = getSessionLocalStorage();
    }

    if (session) return session;

    return null;
}

export async function setLocalStorageSession(userToken : IUserToken | null) : Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        await setSessionIndexedDB(userToken);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        setSessionLocalStorage(userToken);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (sessionCallback) {
        sessionCallback(userToken);
    }
}

function setSessionLocalStorage(userToken : IUserToken | null) : void {
    if (userToken) {
        localStorage.setItem(SESSION_KEY, JSON.stringify(userToken));
    } else {
        localStorage.removeItem(SESSION_KEY);
    }
}

function getSessionLocalStorage() : IUserToken | null {
    const session = localStorage.getItem(SESSION_KEY);

    if (session) return JSON.parse(session);

    return null;
}

/**
 * Creates all object stores up to the current DB version. i.e. for version 2, this function will execute for versions
 * 0, 1 and 2.
 * @param db
 */
export function upgradeDb(db : UpgradeDB) : void {
    // Session Store
    if (!db.objectStoreNames.contains(SESSION_NAME)) {
        db.createObjectStore<IUserToken, string>(SESSION_NAME);
    }

    // Master Data Stores
    
    if (!db.objectStoreNames.contains(ACTIVITY_TABLE_NAME)) {
        db.createObjectStore<IActivity, string>(ACTIVITY_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(ACTIVITY_TYPE_TABLE_NAME)) {
        db.createObjectStore<IActivityType, string>(ACTIVITY_TYPE_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(BLOCK_TABLE_NAME)) {
        db.createObjectStore<IBlock, string>(BLOCK_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(CLOCKING_METHOD_TABLE_NAME)) {
        db.createObjectStore<IClockingMethod, string>(CLOCKING_METHOD_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(CLOCKING_SYSTEM_TABLE_NAME)) {
        db.createObjectStore<IClockingSystem, string>(CLOCKING_SYSTEM_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(EMPLOYEE_SETUP_TABLE_NAME)) {
        db.createObjectStore<IEmployeeSetup, string>(EMPLOYEE_SETUP_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(COMMODITY_TABLE_NAME)) {
        db.createObjectStore<ICommodity, string>(COMMODITY_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(CONTACT_US_TABLE_NAME)) {
        db.createObjectStore<IContactUs, string>(CONTACT_US_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(COUNTRY_TABLE_NAME)) {
        db.createObjectStore<ICountry, string>(COUNTRY_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME)) {
        db.createObjectStore<ICountry, string>(COUNTRY_PUBLIC_HOLIDAY_RULE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(CROP_TABLE_NAME)) {
        db.createObjectStore<ICrop, string>(CROP_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(DEPARTMENT_TABLE_NAME)) {
        db.createObjectStore<IDepartment, string>(DEPARTMENT_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(DIVISION_TABLE_NAME)) {
        db.createObjectStore<IDivision, string>(DIVISION_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(DIVISION_DAYS_OF_WEEK_TABLE_NAME)) {
        db.createObjectStore<IDivisionDayOfWeek, string>(DIVISION_DAYS_OF_WEEK_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(EMPLOYEE_TABLE_NAME)) {
        db.createObjectStore<IEmployee, string>(EMPLOYEE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(EMPLOYEE_RATE_TABLE_NAME)) {
        db.createObjectStore<IEmployeeRate, string>(EMPLOYEE_RATE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(FIELD_TABLE_NAME)) {
        db.createObjectStore<IField, string>(FIELD_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(JOB_TYPE_TABLE_NAME)) {
        db.createObjectStore<IJobType, string>(JOB_TYPE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(MINIMUM_WAGE_HISTORY_TABLE_NAME)) {
        db.createObjectStore<IMinimumWageHistory, string>(MINIMUM_WAGE_HISTORY_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(NEWS_TABLE_NAME)) {
        db.createObjectStore<INews, string>(NEWS_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(PRODUCTION_UNIT_TABLE_NAME)) {
        db.createObjectStore<IProductionUnit, string>(PRODUCTION_UNIT_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(PROJECT_TABLE_NAME)) {
        db.createObjectStore<IProject, string>(PROJECT_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(PUBLIC_HOLIDAY_TABLE_NAME)) {
        db.createObjectStore<IPublicHoliday, string>(PUBLIC_HOLIDAY_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(ROOT_STOCK_TABLE_NAME)) {
        db.createObjectStore<IRootStock, string>(ROOT_STOCK_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(SCAN_TYPE_TABLE_NAME)) {
        db.createObjectStore<IScanType, string>(SCAN_TYPE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(SUBDIVISION_TABLE_NAME)) {
        db.createObjectStore<ISubdivision, string>(SUBDIVISION_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(TARIF_CALCULATION_TABLE_NAME)) {
        db.createObjectStore<ITarifCalculation, string>(TARIF_CALCULATION_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(TARIF_TYPE_TABLE_NAME)) {
        db.createObjectStore<ITarifType, string>(TARIF_TYPE_TABLE_NAME);
    }
    
    if (!db.objectStoreNames.contains(VARIETY_TABLE_NAME)) {
        db.createObjectStore<IVariety, string>(VARIETY_TABLE_NAME);
    }    

    if (!db.objectStoreNames.contains(NORM_TABLE_NAME)) {
        db.createObjectStore<INorm, string>(NORM_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(PAY_RUN_TABLE_NAME)) {
        db.createObjectStore<IPayRun, string>(PAY_RUN_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(ACTIVITY_RATE_TABLE_NAME)) {
        db.createObjectStore<IActivityRate, string>(ACTIVITY_RATE_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(COMPANY_REFERENCE_TABLE_NAME)) {
        db.createObjectStore<ICompanyReference, string>(COMPANY_REFERENCE_TABLE_NAME);
    }

    if (!db.objectStoreNames.contains(BUSINESS_RULE_TABLE_NAME)) {
        db.createObjectStore<IBusinessRule, string>(BUSINESS_RULE_TABLE_NAME);
    }
    
    if (db.objectStoreNames.contains('pay-point-table')) {
        db.deleteObjectStore('pay-point-table');
    }
    
    if (db.objectStoreNames.contains('clocking-system-employee-setup-table')) {
        db.deleteObjectStore('clocking-system-employee-setup-table');
    }
    
    if (db.objectStoreNames.contains('employee-rate-history-table')) {
        db.deleteObjectStore('employee-rate-history-table');
    }

    // Process Wizard Stores
    processWizardIndexedDBStores(db);
}

/**
 * Sets the auth session. If no session is specified, deletes the existing entry.
 * @param userToken The session.
 */
async function setSessionIndexedDB(userToken : IUserToken | null) : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readwrite');

    const store = tx.objectStore(SESSION_NAME);

    await store.delete(SESSION_KEY);
    if (userToken) {
        await store.add(userToken, SESSION_KEY);
    }
    await tx.complete;
}

/**
 * Opens the DB and retrieves the current auth session.
 */
async function getSessionIndexedDB() : Promise<IUserToken> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readonly');

    const result = tx.objectStore<IUserToken>(SESSION_NAME).get(SESSION_KEY);

    await tx.complete;

    return result;
}

/**
 * Specifies the callback that will be fired whenever the auth session undergoes a change.
 * @param callback
 */
// eslint-disable-next-line no-unused-vars
export async function onSessionChanged(callback : (userToken : IUserToken | null) => void) : Promise<void> {
    sessionCallback = callback;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        indexedDBSessionChange();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        const session = getSessionLocalStorage();
        sessionCallback(session);
    }
}

/**
 * Retrieves auth session, and once done fires the session callback.
 */
function indexedDBSessionChange() : void {
    getSessionIndexedDB().then((res) => {
        sessionCallback(res);
    }, () => {
        sessionCallback(null);
    });
}

///////////////////

/**
 * Stores User Selected Division Ids
 */
export function setUserSelectedDivisionsLocalStorage(divisionIds ?: Array<IOptionType>) : void {
    if (divisionIds) {
        localStorage.setItem(SELECTED_USER_DIVISIONS_KEY, JSON.stringify(divisionIds));
    } else {
        localStorage.removeItem(SELECTED_USER_DIVISIONS_KEY);
    }
}

/**
 * Retrieves User Selected Division Ids
 */
export function getUserSelectedDivisionsLocalStorage() : Array<IOptionType> {
    const divisionIds = localStorage.getItem(SELECTED_USER_DIVISIONS_KEY);

    if (divisionIds) return JSON.parse(divisionIds);

    return [];
}

/**
 * Stores User Selected Department Ids
 */
export function setUserSelectedDepartmentsLocalStorage(departmentIds ?: Array<IOptionType>) : void {
    if (departmentIds) {
        localStorage.setItem(SELECTED_USER_DEPARTMENTS_KEY, JSON.stringify(departmentIds));
    } else {
        localStorage.removeItem(SELECTED_USER_DEPARTMENTS_KEY);
    }
}

/**
 * Retrieves User Selected Department Ids
 */
export function getUserSelectedDepartmentsLocalStorage() : Array<IOptionType> {
    const departmentIds = localStorage.getItem(SELECTED_USER_DEPARTMENTS_KEY);

    if (departmentIds) return JSON.parse(departmentIds);

    return [];
}

/**
 * Stores master data last sync date
 */
export function setMasterDataLastSyncDateLocalStorage(lastSyncDate ?: moment.Moment) : void {
    if (lastSyncDate) {
        localStorage.setItem(MASTER_DATA_LAST_SYNC_DATE, JSON.stringify(lastSyncDate));
    } else {
        localStorage.removeItem(MASTER_DATA_LAST_SYNC_DATE);
    }
}

/**
 * Retrieves master data last sync date
 */
export function getMasterDataLastSyncDateLocalStorage() : moment.Moment | null {
    const lastSyncDate = localStorage.getItem(MASTER_DATA_LAST_SYNC_DATE);

    if (lastSyncDate) return JSON.parse(lastSyncDate);

    return null;
}

/**
 * Stores master data last sync date
 */
export function setUserSelectedDateFormatLocalStorage(selectedFormat : string | null) : void {
    if (selectedFormat) {
        localStorage.setItem(SELECTED_DATE_FORMAT, JSON.stringify(selectedFormat));
    } else {
        localStorage.removeItem(SELECTED_DATE_FORMAT);
    }
}

/**
 * Retrieves master data last sync date
 */
export function getUserSelectedDateFormatLocalStorage() : string | null {
    const selectedFormat = localStorage.getItem(SELECTED_DATE_FORMAT);

    if (selectedFormat) return JSON.parse(selectedFormat);

    return null;
}

/**
 * Stores master data last sync date
 */
export function setSelectedPaymentTransactionLocalStorage(selectedPaymentTransaction : IPaymentTransaction | null) : void {
    if (selectedPaymentTransaction) {
        localStorage.setItem(SELECTED_PAYMENT_TRANSACTION, JSON.stringify(selectedPaymentTransaction));
    } else {
        localStorage.removeItem(SELECTED_PAYMENT_TRANSACTION);
    }
}

/**
 * Retrieves master data last sync date
 */
export function getSelectedPaymentTransactionLocalStorage() : IPaymentTransaction | null {
    const selectedPaymentTransaction = localStorage.getItem(SELECTED_PAYMENT_TRANSACTION);

    if (selectedPaymentTransaction) return JSON.parse(selectedPaymentTransaction);

    return null;
}
