/* eslint-disable no-unused-vars */
export enum RIGHT_GRANTS {
    View = 0,
    Add = 1,
    Edit = 2,
    Delete = 3,
    Admin = 20,
}

export const RIGHT_LEVELS : Record<string, number> = {
    'View': RIGHT_GRANTS.View,
    'Add': RIGHT_GRANTS.Add,
    'Edit': RIGHT_GRANTS.Edit,
    'Delete': RIGHT_GRANTS.Delete,
    'Admin': RIGHT_GRANTS.Admin,
};

export const RIGHT_GRANT_LEVELS : Array<{ label : string; value : number }> = [
    { label: 'View', value: RIGHT_GRANTS.View },
    { label: 'Add', value: RIGHT_GRANTS.Add },
    { label: 'Edit', value: RIGHT_GRANTS.Edit },
    { label: 'Delete', value: RIGHT_GRANTS.Delete },
    { label: 'Admin', value: RIGHT_GRANTS.Admin },
];

export enum PAYMENT_TRANSACTION_STATUSES {
    Draft = 'Draft',
    Batching = 'Batching',
    Approved = 'Approved',
    Extracted = 'Extracted',
    Rejected = 'Rejected',
}

export const PAYMENT_TRANSACTION_STATUSES_OPTIONS : Array<{ label : string; value : number }> = [
    { label: 'Draft', value: 0 },
    { label: 'Batching', value: 1 },
    { label: 'Approved', value: 2 },
    { label: 'Extracted', value: 3 },
    { label: 'Rejected', value: 4 },
];

export enum BATCH_TRANSACTION_STATUSES {
    Draft = 'Draft',
    Batching = 'Batching',
    Approved = 'Approved',
    Extracted = 'Extracted',
    Rejected = 'Rejected',
}

export const BATCH_STATUSES_ORDER : Record<string, number> = {
    'Draft': 0,
    'Batching': 1,
    'Approved': 2,
    'Extracted': 3,
    'Rejected': 4,
};

// Always use white space betweet date and time.
export const DATE_FORMAT_DEFAULT_NO_SECONDS = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_FORMAT_DEFAULT_NO_TIME = 'DD/MM/YYYY';
export const DATE_FORMAT_NO_SECONDS = 'YYYYMMDD HH:mm';
export const DATE_FORMAT_SUMMARY = 'DD-MM-YYYY';

export const MAP_CENTER_POSITION : { lat : number; long : number } = {
    lat: -23.5792,
    long: 30.1424,
};

/*ZZFresh service */

export enum FreshServicePriority {
    Na = 0,
    Low,
    Medium,
    High,
    Urgent,
}

export enum FreshServiceSourceType {
    Na = 0,
    Email,
    Portal,
    Phone,
    Chat,
    FeedbackWidget,
    Yammer,
    AwasCloudwatch,
    PageDuty,
    Walkup,
    Slack,
}

export enum FreshServiceStatus {
    Na = 1,
    Open,
    Pending,
    Resolved,
    Closed,
}

/* Auth Types */

export enum AUTH_TYPES {
    Bearer = 'Bearer',
    APIKey = 'APIKey',
    Basic = 'Basic',
}

export const AUTH_TYPE_OPTIONS : Array<{ label : string; value : number }> = [
    { label: 'Bearer', value: 0 },
    { label: 'APIKey', value: 1 },
    { label: 'Basic', value: 2 },
];
