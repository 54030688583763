import axios, { AxiosPromise, Canceler, CancelTokenStatic } from 'axios';
import { ITarifCalculation } from '../../../@types/model/masterData/tarifCalculation/tarifCalculation';
import { ITarifCalculationUpsert } from '../../../@types/model/masterData/tarifCalculation/tarifCalculationUpsert';

export default class TarifCalculationHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataTarifCalculationGetList() : AxiosPromise<Array<ITarifCalculation>> {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        return axios.get(`${API_URL}/v1/MasterData/TarifCalculation/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
    }

    public static tarifCalculationUpsert = (tarifCalculationUpsert : ITarifCalculationUpsert, type : 'Add' | 'Edit') : AxiosPromise<ITarifCalculation> => {
        return axios.post(`${API_URL}/v1/MasterData/TarifCalculation/${type}`, tarifCalculationUpsert);
    }

    public static tarifCalculationDelete = (id : number) : AxiosPromise<ITarifCalculation> => {
        return axios.delete(`${API_URL}/v1/MasterData/TarifCalculation/Delete`, {
            params: { id },
        });
    }
}