import DataActions from './actions';
import GeneralThunk from '../general/thunk';
import ArrayHelper from '../../service/helper/arrayHelper';
import lodash from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from '../../@types/redux';
import { IDivision } from '../../@types/model/masterData/division/division';
import { ISubdivision } from '../../@types/model/masterData/subdivision/subdivision';
import { IProductionUnit } from '../../@types/model/masterData/productionUnit/productionUnit';
import DivisionHttpService from '../../service/http/masterData/divisionHttpService';
import SubdivisionHttpService from '../../service/http/masterData/subdivisionHttpService';
import ProductionUnitHttpService from '../../service/http/masterData/productionUnitHttpService';
import FieldHttpService from '../../service/http/masterData/fieldHttpService';
import { IField } from '../../@types/model/masterData/field/field';
import { IBlock } from '../../@types/model/masterData/block/block';
import BlockHttpService from '../../service/http/masterData/blockHttpService';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import CommodityHttpService from '../../service/http/masterData/commodityHttpService';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import VarietyHttpService from '../../service/http/masterData/varietyHttpService';
import { IProject } from '../../@types/model/masterData/project/project';
import ProjectHttpService from '../../service/http/masterData/projectHttpService';
import { IDepartment } from '../../@types/model/masterData/department/department';
import DepartmentHttpService from '../../service/http/masterData/departmentHttpService';
import { IDivisionUpsert } from '../../@types/model/masterData/division/divisionUpsert';
import { ISubdivisionUpsert } from '../../@types/model/masterData/subdivision/subdivisionUpsert';
import { IDepartmentUpsert } from '../../@types/model/masterData/department/departmentUpsert';
import { IProjectUpsert } from '../../@types/model/masterData/project/projectUpsert';
import { IProductionUnitUpsert } from '../../@types/model/masterData/productionUnit/productionUnitUpsert';
import { IFieldUpsert } from '../../@types/model/masterData/field/fieldUpsert';
import { IBlockUpsert } from '../../@types/model/masterData/block/blockUpsert';
import { ICommodityUpsert } from '../../@types/model/masterData/commodity/commodityUpsert';
import { IVarietyUpsert } from '../../@types/model/masterData/variety/varietyUpsert';
import { ICountry } from '../../@types/model/masterData/country/country';
import CountryHttpService from '../../service/http/masterData/countryHttpService';
import { ICountryUpsert } from '../../@types/model/masterData/country/countryUpsert';
import { IContactUs } from '../../@types/model/masterData/contactUs/contactUs';
import ContactUsHttpService from '../../service/http/masterData/contactUsHttpService';
import { INews } from '../../@types/model/masterData/news/news';
import NewsHttpService from '../../service/http/masterData/newsHttpService';
import { IContactUsUpsert } from '../../@types/model/masterData/contactUs/contactUsUpsert';
import { INewsUpsert } from '../../@types/model/masterData/news/newsUpsert';
import { IActivity } from '../../@types/model/masterData/activity/activity';
import ActivityHttpService from '../../service/http/masterData/activityHttpService';
import { ICrop } from '../../@types/model/masterData/crop/crop';
import CropHttpService from '../../service/http/masterData/cropHttpService';
import { ICropLine } from '../../@types/model/masterData/cropLine/cropLine';
import CropLineHttpService from '../../service/http/masterData/cropLineHttpService';
import { IRootStock } from '../../@types/model/masterData/rootStock/rootStock';
import RootStockHttpService from '../../service/http/masterData/rootStockHttpService';
import { IActivityUpsert } from '../../@types/model/masterData/activity/activityUpsert';
import { IRootStockUpsert } from '../../@types/model/masterData/rootStock/rootStockUpsert';
import { IClockingMethod } from '../../@types/model/masterData/clockingMethod/clockingMethod';
import ClockingMethodHttpService from '../../service/http/masterData/clockingMethodHttpService';
import { IClockingSystem } from '../../@types/model/masterData/clockingSystem/clockingSystem';
import ClockingSystemHttpService from '../../service/http/masterData/clockingSystemHttpService';
import { IEmployeeSetup } from '../../@types/model/masterData/employeeSetup/employeeSetup';
import EmployeeSetupHttpService from '../../service/http/masterData/employeeSetupHttpService';
import { IDivisionDayOfWeek } from '../../@types/model/masterData/divisionDayOfWeek/divisionDayOfWeek';
import DivisionDayOfWeekHttpService from '../../service/http/masterData/divisionDayOfWeekHttpService';
import { IEmployee } from '../../@types/model/masterData/employee/employee';
import EmployeeHttpService from '../../service/http/masterData/employeeHttpService';
import { IEmployeeRate } from '../../@types/model/masterData/employeeRate/employeeRate';
import { IJobType } from '../../@types/model/masterData/jobType/jobType';
import JobTypeHttpService from '../../service/http/masterData/jobTypeHttpService';
import { IMinimumWageHistory } from '../../@types/model/masterData/minimumWageHistory/minimumWageHistory';
import MinimumWageHistoryHttpService from '../../service/http/masterData/minimumWageHistoryHttpService';
import { IPublicHoliday } from '../../@types/model/masterData/publicHoliday/publicHoliday';
import { IScanType } from '../../@types/model/masterData/scanType/scanType';
import { ITarifCalculation } from '../../@types/model/masterData/tarifCalculation/tarifCalculation';
import { ITarifType } from '../../@types/model/masterData/tarifType/tarifType';
import TarifTypeHttpService from '../../service/http/masterData/tarifTypeHttpService';
import { IClockingMethodUpsert } from '../../@types/model/masterData/clockingMethod/clockingMethodUpsert';
import { IClockingSystemUpsert } from '../../@types/model/masterData/clockingSystem/clockingSystemUpsert';
import { IEmployeeSetupUpsert } from '../../@types/model/masterData/employeeSetup/employeeSetupUpsert';
import { IDivisionDayOfWeekUpsert } from '../../@types/model/masterData/divisionDayOfWeek/divisionDayOfWeekUpsert';
import { IEmployeeUpsert } from '../../@types/model/masterData/employee/employeeUpsert';
import { IEmployeeRateUpsert } from '../../@types/model/masterData/employeeRate/employeeRateUpsert';
import { IJobTypeUpsert } from '../../@types/model/masterData/jobType/jobTypeUpsert';
import { IMinimumWageHistoryUpsert } from '../../@types/model/masterData/minimumWageHistory/minimumWageHistoryUpsert';
import { IPublicHolidayUpsert } from '../../@types/model/masterData/publicHoliday/publicHolidayUpsert';
import { IScanTypeUpsert } from '../../@types/model/masterData/scanType/scanTypeUpsert';
import { ITarifCalculationUpsert } from '../../@types/model/masterData/tarifCalculation/tarifCalculationUpsert';
import { ITarifTypeUpsert } from '../../@types/model/masterData/tarifType/tarifTypeUpsert';
import EmployeeRateHttpService from '../../service/http/masterData/employeeRateHttpSerivce';
import PublicHolidayHttpService from '../../service/http/masterData/publicHolidayHttpService';
import ScanTypeHttpService from '../../service/http/masterData/scanTypeHttpService';
import TarifCalculationHttpService from '../../service/http/masterData/tarifCalculationHttpService';
import { IMasterDataSync } from '../../@types/model/sync/masterDataSync';
import { getMasterDataLastSyncDateLocalStorage, setMasterDataLastSyncDateLocalStorage } from '../../service/localStorageService';
import moment from 'moment';
import SyncHttpService from '../../service/http/masterData/syncHttpService';
import { setIndexedDBMasterData,
    clearIndexedDBObjectStores,
    setBlockMasterDataIndexedDB,
    setContactUsMasterDataIndexedDB,
    setNewsMasterDataIndexedDB,
    setCountryMasterDataIndexedDB,
    setDivisionMasterDataIndexedDB,
    setSubdivisionMasterDataIndexedDB,
    setDepartmentMasterDataIndexedDB,
    setProductionUnitMasterDataIndexedDB,
    setFieldMasterDataIndexedDB,
    setCommodityMasterDataIndexedDB,
    setVarietyMasterDataIndexedDB,
    setProjectMasterDataIndexedDB,
    setActivityMasterDataIndexedDB,
    setActivityTypeMasterDataIndexedDB,
    setRootStockMasterDataIndexedDB,
    setClockingMethodMasterDataIndexedDB,
    setClockingSystemMasterDataIndexedDB,
    setDivisionDaysOfWeekMasterDataIndexedDB,
    setEmployeeMasterDataIndexedDB,
    setEmployeeRateMasterDataIndexedDB,
    setJobTypeMasterDataIndexedDB,
    setMinimumWageHistoryMasterDataIndexedDB,
    setPublicHolidayMasterDataIndexedDB,
    setScanTypeMasterDataIndexedDB,
    setTarifCalculationMasterDataIndexedDB,
    setTarifTypeMasterDataIndexedDB,
    setEmployeeSetupMasterDataIndexedDB,
    setCropMasterDataIndexedDB, 
    setCountryPublicHolidayRuleMasterDataIndexedDB,
    setPayRunMasterDataIndexedDB,
    setNormMasterDataIndexedDB,
    setActivityRateMasterDataIndexedDB,
    setCompanyReferenceMasterDataIndexedDB,
    setBusinessRuleMasterDataIndexedDB} from '../../service/masterDataSyncService';
import { IActivityType } from '../../@types/model/masterData/activityType/activityType';
import ActivityTypeHttpService from '../../service/http/masterData/activityTypeHttpService';
import { IActivityTypeUpsert } from '../../@types/model/masterData/activityType/activityTypeUpsert';
import { ICropUpsert } from '../../@types/model/masterData/crop/cropUpsert';
import { ICountryPublicHolidayRule } from '../../@types/model/masterData/countryPublicHolidayRule/countryPublicHolidayRule';
import CountryPublicHolidayRuleHttpService from '../../service/http/masterData/countryPublicHolidayRuleHttpService';
import { ICountryPublicHolidayRuleUpsert } from '../../@types/model/masterData/countryPublicHolidayRule/countryPublicHolidayRuleUpsert';
import { IPayRun } from '../../@types/model/masterData/payRun/payRun';
import PayRunHttpService from '../../service/http/masterData/payRunHttpService';
import { IPayRunUpsert } from '../../@types/model/masterData/payRun/pyaRunUpsert';
import { INorm } from '../../@types/model/masterData/norm/norm';
import { INormUpsert } from '../../@types/model/masterData/norm/normUpsert';
import NormHttpService from '../../service/http/masterData/normHttpService';
import { IActivityRate } from '../../@types/model/masterData/activityRate/activityRate';
import ActivityRateHttpService from '../../service/http/masterData/activityRateHttpService';
import { IActivityRateUpsert } from '../../@types/model/masterData/activityRate/activityRateUpsert';
import CompanyReferenceHttpService from '../../service/http/masterData/companyReferenceHttpService';
import { ICompanyReference } from '../../@types/model/masterData/companyReference/companyReference';
import { ICompanyReferenceUpsert } from '../../@types/model/masterData/companyReference/companyReferenceUpsert';
import BusinessRuleHttpService from '../../service/http/masterData/businessRuleHttpService';
import { IBusinessRule } from '../../@types/model/masterData/businessRule/businessRule';
import { IBusinessRuleUpsert } from '../../@types/model/masterData/businessRule/businessRuleUpsert';
import { IBusinessRuleLineUpsert } from '../../@types/model/masterData/businessRuleLine/businessRuleLineUpsert';
import { IBusinessRuleLine } from '../../@types/model/masterData/businessRuleLine/businessRuleLine';
import BusinessRuleLineHttpService from '../../service/http/masterData/businessRuleLineHttpService';

export default class MasterDataThunk {

        /**
     * Sync all master data filtered by last sync date from the API,
     * Updating the redux state and indexedDB object stores once complete.
     *
     * @returns {IMasterDataSync | null}
     */
        public static syncMasterData = createAsyncThunk<
            IMasterDataSync | null,
            undefined,
            ThunkApi>(
                'MASTER_DATA_SYNC_ALL',
                async (params, thunkApi) => {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    let result : IMasterDataSync | null = null;

                    // update last sync date
                    try {
                        const lastSyncDate : moment.Moment | null = getMasterDataLastSyncDateLocalStorage();
                        const newMasterDataLastSyncDate : moment.Moment = moment().utc().subtract(1, 'hours');

                        setMasterDataLastSyncDateLocalStorage(newMasterDataLastSyncDate);

                        const lastSyncDateUnix = lastSyncDate ? moment(lastSyncDate).unix() * 1000 : null;

                        const res = await SyncHttpService.masterDataSyncAll(lastSyncDateUnix);

                        result = res.data;

                    } catch (e) {
                        /**
                         * Only show error if there is actually an error.
                         * Cancelation errors are empty.
                         */
                        if (e) {
                            thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while trying to load master data.', e }));
                            thunkApi.dispatch(DataActions.setIsLoading(false));
                        }
                        return null;
                    }

                    // update master data stores
                    try {
                        // Update indexedDB master data objects stores with new values
                        const updatedMasterData = await setIndexedDBMasterData(result);

                        // Update redux with new master data
                        thunkApi.dispatch(DataActions.setSyncedMasterData(updatedMasterData));
                        return updatedMasterData;

                    } catch (e) {
                        /**
                         * Only show error if there is actually an error.
                         * Cancelation errors are empty.
                         */
                        if (e) {
                            thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while trying update master data.', e }));
                        }
                        return null;

                    } finally {
                        thunkApi.dispatch(DataActions.setIsLoading(false));
                    }
                }
            )
    
    /**
     * Sync all master data from the API,
     * Updating the redux state and indexedDB object stores once complete.
     *
     * @returns {IMasterDataSync | null}
     */
    public static manualSyncAllMasterData = createAsyncThunk<
    IMasterDataSync | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_MANUAL_SYNC_ALL',
        async (params, thunkApi) => {
            thunkApi.dispatch(DataActions.setIsMasterDataSyncRunning(true));

            let result : IMasterDataSync | null = null;

            try {
                const newMasterDataLastSyncDate : moment.Moment = moment().utc().subtract(1, 'hours');
                
                // Update last sync value to new value
                setMasterDataLastSyncDateLocalStorage(newMasterDataLastSyncDate);

                const res = await SyncHttpService.masterDataSyncAll(null);

                result = res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while trying to load master data.', e }));
                    thunkApi.dispatch(DataActions.setIsMasterDataSyncRunning(false));
                }
                return null;
            }

            try {
                // clears all indexedDB object stores before updating
                await clearIndexedDBObjectStores();

                // Update indexedDB master data objects stores with new values
                const updatedMasterData = await setIndexedDBMasterData(result);

                // Update redux with new master data
                thunkApi.dispatch(DataActions.setSyncedMasterData(updatedMasterData));

                return updatedMasterData;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while trying update master data.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsMasterDataSyncRunning(false));
            }
        },
    );

    /*================================================================================================================
    *                                                  GET
    * ==============================================================================================================*/
    /**
     * Retrieves the list of contact us data from the API, updating the redux state once complete.
     *
     * @param {boolean} onlyHomeView
     * @returns {Array<IContactUs> | null}
     */
    public static getContactUsList = createAsyncThunk<
    Array<IContactUs> | null,
    boolean | null,
    ThunkApi>(
        'MASTER_DATA_LOAD_CONTACT_US_DATA',
        async (onlyHomeView, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ContactUsHttpService.masterDataContactUsGetList(onlyHomeView ?? false);

                thunkApi.dispatch(DataActions.setContactUsData(res.data));

                return res.data;

            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading contact us data.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of news data from the API, updating the redux state once complete.
     *
     * @returns {Array<INews> | null}
     */
    public static getNewsList = createAsyncThunk<
    Array<INews> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_NEWS_DATA',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await NewsHttpService.masterDataNewsGetList();

                thunkApi.dispatch(DataActions.setNewsData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading news data.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of countries from the API, updating the redux state once complete.
     *
     * @returns {Array<ICountry> | null}
     */
    public static getCountryList = createAsyncThunk<
    Array<ICountry> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_COUNTRIES',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CountryHttpService.masterDataCountryGetList();

                thunkApi.dispatch(DataActions.setCountryData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading countries.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of country public holiday rules from the API, updating the redux state once complete.
     *
     * @returns {Array<ICountryPublicHolidayRule> | null}
     */
    public static getCountryPublicHolidayRuleList = createAsyncThunk<
    Array<ICountryPublicHolidayRule> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_COUNTRY_PUBLIC_HOLIDAY_RULE',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CountryPublicHolidayRuleHttpService.masterDataCountryPublicHolidayRuleGetList();

                thunkApi.dispatch(DataActions.setCountryPublicHolidayRuleData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading country public holiday rules.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of divisions from the API, updating the redux state once complete.
     *
     * @returns {Array<IDivision> | null}
     */
    public static getDivisionList = createAsyncThunk<
    Array<IDivision> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_DIVISIONS',
        async (divisionIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await DivisionHttpService.masterDataDivisionGetList();

                thunkApi.dispatch(DataActions.setDivisionData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading divisions.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of divisions matching the provided ids from the API, updating the redux state once complete.
     *
     * @param {Array<number>} divisionIds
     * @returns {Array<IDivision> | null}
     */
    public static getDivisionListByIds = createAsyncThunk<
    Array<IDivision> | null,
    Array<number> | null,
    ThunkApi>(
        'MASTER_DATA_LOAD_DIVISIONS',
        async (divisionIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await DivisionHttpService.masterDataDivisionGetListByIds(divisionIds);

                thunkApi.dispatch(DataActions.setDivisionData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading divisions.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of subdivisions from the API, updating the redux state once complete.
     *
     * @returns {Array<ISubdivision> | null}
     */

    public static getSubdivisionList = createAsyncThunk<
    Array<ISubdivision> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_SUBDIVISIONS_BY_DIVISION',
        async (divisionIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await SubdivisionHttpService.masterDataSubdivisionGetList();

                thunkApi.dispatch(DataActions.setSubdivisionData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading subdivisions.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of subdivisions filtered on the division ids from the API, updating the redux state once complete.
     *
     * @param {Array<number>} divisionIds
     * @returns {Array<ISubdivision> | null}
     */

    public static getSubdivisionListByDivision = createAsyncThunk<
    Array<ISubdivision> | null,
    Array<number>,
    ThunkApi>(
        'MASTER_DATA_LOAD_SUBDIVISIONS_BY_DIVISION',
        async (divisionIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await SubdivisionHttpService.masterDataSubdivisionGetListByDivisionIds(divisionIds);

                thunkApi.dispatch(DataActions.setSubdivisionData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading subdivisions.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of departments from the API, updating the redux state once complete.
     *
     * @returns {Array<IDepartment> | null}
     */
    public static getDepartmentList = createAsyncThunk<
    Array<IDepartment> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_DEPARTMENTS',
        async (departmentIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await DepartmentHttpService.masterDataDepartmentGetList();

                thunkApi.dispatch(DataActions.setDepartmentData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading departments.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of departments filtered given ids from the API, updating the redux state once complete.
     *
     * @param {Array<number>} departmentIds
     * @returns {Array<IDepartment> | null}
     */
    public static getDepartmentListByIds = createAsyncThunk<
    Array<IDepartment> | null,
    Array<number> | null,
    ThunkApi>(
        'MASTER_DATA_LOAD_DEPARTMENTS_BY_IDS',
        async (departmentIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await DepartmentHttpService.masterDataDepartmentGetListByIds(departmentIds);

                thunkApi.dispatch(DataActions.setDepartmentData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading departments.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of production units from the API, updating the redux state once complete.
     *
     * @returns {Array<IProductionUnit> | null}
     */
    public static getProductionUnitList = createAsyncThunk<
    Array<IProductionUnit> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_PRODUCTION_UNITS',
        async (divisionIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ProductionUnitHttpService.masterDataProductionUnitGetList();

                thunkApi.dispatch(DataActions.setProductionUnitData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading production units.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of field from the API, updating the redux state once complete.
     *
     * @param {Array<number> | undefined} departmentIds
     * @param {Array<number> | undefined} productionUnitIds
     * @returns {Array<IField> | null}
     */
    public static getFieldList = createAsyncThunk<
    Array<IField> | null,
    {
        departmentIds ?: Array<number>;
        productionUnitIds ?: Array<number>;
    } | undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_FIELDS',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await FieldHttpService.masterDataFieldGetList(params?.departmentIds, params?.productionUnitIds);

                thunkApi.dispatch(DataActions.setFieldData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading fields.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of blocks from the API, updating the redux state once complete.
     *
     * @param {Array<number>} departmentIds
     * @param {number | undefined} divisionId
     * @param {number | undefined} productionUnitId
     * @param {number | undefined} fieldId
     * @param {number | undefined} projectId
     * @returns {Array<IBlock> | null}
     */
    public static getBlockList = createAsyncThunk<
    Array<IBlock> | null,
    {
        departmentIds : Array<number>;
        divisionId ?: number;
        departmentId ?: number;
        productionUnitId ?: number;
        fieldId ?: number;
        projectId ?: number;
    },
    ThunkApi>(
        'MASTER_DATA_LOAD_BLOCKS',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await BlockHttpService.masterDataBlockGetList(params.departmentIds, params.divisionId, params.productionUnitId, params.fieldId, params.projectId);

                thunkApi.dispatch(DataActions.setBlockData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading blocks.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the block matching the block id from the API, updating the redux state once complete.
     *
     * @param {number} blockId
     * @returns {IBlock | null}
     */
    public static getBlock = createAsyncThunk<
    IBlock | null,
    number,
    ThunkApi>(
        'MASTER_DATA_LOAD_BLOCK',
        async (blockId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await BlockHttpService.masterDataGetBlock(blockId);

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading specified block.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of commodities from the API, updating the redux state once complete.
     *
     * @returns {Array<ICommodity> | null}
     */
    public static getCommodityList = createAsyncThunk<
    Array<ICommodity> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_COMMODITIES',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CommodityHttpService.masterDataCommodityGetList();

                thunkApi.dispatch(DataActions.setCommodityData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading commodities.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of varieties from the API, updating the redux state once complete.
     *
     * @returns {Array<IVariety> | null}
     */
    public static getVarietyList = createAsyncThunk<
    Array<IVariety> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_VARIETIES',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await VarietyHttpService.masterDataVarietyGetList();

                thunkApi.dispatch(DataActions.setVarietyData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading varieties.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of projects from the API, updating the redux state once complete.
     *
     * @returns {Array<IProject> | null}
     */
    public static getProjectList = createAsyncThunk<
    Array<IProject> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_PROJECTS',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ProjectHttpService.masterDataProjectGetList();

                thunkApi.dispatch(DataActions.setProjectData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading projects.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of activities from the API, updating the redux state once complete.
     *
     * @returns {Array<IActivity> | null}
     */
    public static getActivityList = createAsyncThunk<
    Array<IActivity> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_ACTIVITY',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ActivityHttpService.masterDataActivityGetList();

                thunkApi.dispatch(DataActions.setActivityData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading activities.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of activity type from the API, updating the redux state once complete.
     *
     * @returns {Array<IActivityType> | null}
     */
    public static getActivityTypeList = createAsyncThunk<
    Array<IActivityType> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_ACTIVITY_TYPE',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ActivityTypeHttpService.masterDataActivityTypeGetList();

                thunkApi.dispatch(DataActions.setActivityTypeData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading activity types.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of crops from the API, updating the redux state once complete.
     *
     * @param {Array<number>} departmentIds
     * @returns {Array<ICrop> | null}
     */
    public static getCropList = createAsyncThunk<
    Array<ICrop> | null,
    Array<number>,
    ThunkApi>(
        'MASTER_DATA_LOAD_CROP',
        async (departmentIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CropHttpService.masterDataCropGetList(departmentIds);

                thunkApi.dispatch(DataActions.setCropData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading crops.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of crop lines from the API, updating the redux state once complete.
     *
     * @param {Array<number>} cropIds
     * @returns {Array<ICropLine> | null}
     */
    public static getCropLineList = createAsyncThunk<
    Array<ICropLine> | null,
    Array<number>,
    ThunkApi>(
        'MASTER_DATA_LOAD_CROP_LINE',
        async (cropIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CropLineHttpService.masterDataCropLineGetList(cropIds);

                thunkApi.dispatch(DataActions.setCropLineData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading crop lines.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of root stocks from the API, updating the redux state once complete.
     *
     * @returns {Array<IRootStock> | null}
     */
    public static getRootStockList = createAsyncThunk<
    Array<IRootStock> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_ROOT_STOCK',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await RootStockHttpService.masterDataRootStockGetList();

                thunkApi.dispatch(DataActions.setRootStockData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading root stocks.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of clocking methods from the API, updating the redux state once complete.
     *
     * @returns {Array<IClockingMethod> | null}
     */
    public static getClockingMethodList = createAsyncThunk<
    Array<IClockingMethod> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_CLOCKING_METHOD',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ClockingMethodHttpService.masterDataClockingMethodGetList();

                thunkApi.dispatch(DataActions.setClockingMethodData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading clocking methods.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of clocking systems from the API, updating the redux state once complete.
     *
     * @returns {Array<IClockingSystem> | null}
     */
    public static getClockingSystemList = createAsyncThunk<
    Array<IClockingSystem> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_CLOCKING_SYSTEM',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ClockingSystemHttpService.masterDataClockingSystemGetList();

                thunkApi.dispatch(DataActions.setClockingSystemData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading clocking systems.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of clocking systems filtered by department ids from the API, updating the redux state once complete.
     *
     * @param {Array<number>} departmentIds
     * @returns {Array<IClockingSystem> | null}
     */
    public static getClockingSystemListByDepartment = createAsyncThunk<
    Array<IClockingSystem> | null,
    Array<number>,
    ThunkApi>(
        'MASTER_DATA_LOAD_CLOCKING_SYSTEM',
        async (departmentIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ClockingSystemHttpService.masterDataClockingSystemGetListByDepartmentIds(departmentIds);

                thunkApi.dispatch(DataActions.setClockingSystemData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading clocking systems.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of employee setups from the API, updating the redux state once complete.
     *
     * @returns {Array<IEmployeeSetup> | null}
     */
    public static getEmployeeSetupList = createAsyncThunk<
    Array<IEmployeeSetup> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_EMPLOYEE_SETUP',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await EmployeeSetupHttpService.masterDataEmployeeSetupGetList();

                thunkApi.dispatch(DataActions.setEmployeeSetupData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading employee setups.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of division day of the week from the API, updating the redux state once complete.
     *
     * @returns {Array<IDivisionDayOfWeek> | null}
     */
    public static getDivisionDayOfWeekList = createAsyncThunk<
    Array<IDivisionDayOfWeek> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_DIVISION_DAY_OF_WEEK',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await DivisionDayOfWeekHttpService.masterDataDivisionDayOfWeekGetList();

                thunkApi.dispatch(DataActions.setDivisionDayOfWeekData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading division day of the week.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of employees from the API, updating the redux state once complete.
     *
     * @returns {Array<IEmployee> | null}
     */
    public static getEmployeeList = createAsyncThunk<
    Array<IEmployee> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_EMPLOYEE',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await EmployeeHttpService.masterDataEmployeeGetList();

                thunkApi.dispatch(DataActions.setEmployeeData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading employees.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of employees filtered given ids from the API, updating the redux state once complete.
     *
     * @param {Array<number>} divisionIds
     * @returns {Array<IEmployee> | null}
     */
    public static getEmployeeListByDivisionIds = createAsyncThunk<
    Array<IEmployee> | null,
    Array<number>,
    ThunkApi>(
        'MASTER_DATA_LOAD_EMPLOYEE_BY_DIVISION_IDS',
        async (divisionIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await EmployeeHttpService.masterDataEmployeeGetListByDivisionIds(divisionIds);

                thunkApi.dispatch(DataActions.setEmployeeData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading employees.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of employee rates from the API, updating the redux state once complete.
     *
     * @returns {Array<IEmployeeRate> | null}
     */
    public static getEmployeeRateList = createAsyncThunk<
    Array<IEmployeeRate> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_EMPLOYEE_RATE',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await EmployeeRateHttpService.masterDataEmployeeRateGetList();

                thunkApi.dispatch(DataActions.setEmployeeRateData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading employee rates.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of job types from the API, updating the redux state once complete.
     *
     * @returns {Array<IJobType> | null}
     */
    public static getJobTypeList = createAsyncThunk<
    Array<IJobType> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_JOB_TYPE',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await JobTypeHttpService.masterDataJobTypeGetList();

                thunkApi.dispatch(DataActions.setJobTypeData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading job types.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of minimum wage histories from the API, updating the redux state once complete.
     *
     * @returns {Array<IMinimumWageHistory> | null}
     */
    public static getMinimumWageHistoryList = createAsyncThunk<
    Array<IMinimumWageHistory> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_MINIMUM_WAGE_HISTORY',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await MinimumWageHistoryHttpService.masterDataMinimumWageHistoryGetList();

                thunkApi.dispatch(DataActions.setMinimumWageHistoryData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading minimum wage histories.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of public holidays from the API, updating the redux state once complete.
     *
     * @returns {Array<IPublicHoliday> | null}
     */
    public static getPublicHolidayList = createAsyncThunk<
    Array<IPublicHoliday> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_PUBLIC_HOLIDAY',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await PublicHolidayHttpService.masterDataPublicHolidayGetList();

                thunkApi.dispatch(DataActions.setPublicHolidayData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading public holidays.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of scan types from the API, updating the redux state once complete.
     *
     * @returns {Array<IScanType> | null}
     */
    public static getScanTypeList = createAsyncThunk<
    Array<IScanType> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_SCAN_TYPE',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ScanTypeHttpService.masterDataScanTypeGetList();

                thunkApi.dispatch(DataActions.setScanTypeData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading scan types.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of tarif calculations from the API, updating the redux state once complete.
     *
     * @returns {Array<ITarifCalculation> | null}
     */
    public static getTarifCalculationList = createAsyncThunk<
    Array<ITarifCalculation> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_TARIF_CALCULATION',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await TarifCalculationHttpService.masterDataTarifCalculationGetList();

                thunkApi.dispatch(DataActions.setTarifCalculationData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading tarif calculations.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of tarif types from the API, updating the redux state once complete.
     *
     * @returns {Array<ITarifType> | null}
     */
    public static getTarifTypeList = createAsyncThunk<
    Array<ITarifType> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_TARIF_TYPE',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await TarifTypeHttpService.masterDataTarifTypeGetList();

                thunkApi.dispatch(DataActions.setTarifTypeData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading tarif types.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of pay runs from the API, updating the redux state once complete.
     *
     * @returns {Array<IPayRun> | null}
     */
    public static getPayRunList = createAsyncThunk<
    Array<IPayRun> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_PAY_RUN',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await PayRunHttpService.getList();

                thunkApi.dispatch(DataActions.setPayRunData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading pay runs.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of norm data from the API, updating the redux state once complete.
     *
     * @returns {Array<INorm> | null}
     */
    public static getNormList = createAsyncThunk<
    Array<INorm> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_Norm',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await NormHttpService.getList();

                thunkApi.dispatch(DataActions.setNormData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading Norm data.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of activity rate data from the API, updating the redux state once complete.
     *
     * @param {Array<number>} divisionIds
     * @returns {Array<IActivityRate> | null}
     */
    public static getActivityRateList = createAsyncThunk<
    Array<IActivityRate> | null,
    Array<number>,
    ThunkApi>(
        'MASTER_DATA_LOAD_ACTIVITY_RATE',
        async (divisionIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ActivityRateHttpService.masterDataActivityRateGetList(divisionIds);

                thunkApi.dispatch(DataActions.setActivityRateData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading activity rate data.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of company reference data from the API, updating the redux state once complete.
     *
     * @param {Array<number>} divisionIds
     * @returns {Array<ICompanyReference> | null}
     */
    public static getCompanyReferenceList = createAsyncThunk<
    Array<ICompanyReference> | null,
    Array<number>,
    ThunkApi>(
        'MASTER_DATA_LOAD_COMPANY_REFERENCE',
        async (divisionIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CompanyReferenceHttpService.getList(divisionIds);

                thunkApi.dispatch(DataActions.setCompanyReferenceData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading company reference data.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Retrieves the list of business rule data from the API, updating the redux state once complete.
     *
     * @param {Array<number>} divisionIds
     * @returns {Array<IBusinessRule> | null}
     */
    public static getBusinessRuleList = createAsyncThunk<
    Array<IBusinessRule> | null,
    Array<number>,
    ThunkApi>(
        'MASTER_DATA_LOAD_BUSINESS_RULE',
        async (divisionIds, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await BusinessRuleHttpService.getList(divisionIds);

                thunkApi.dispatch(DataActions.setBusinessRuleData(res.data));

                return res.data;
            } catch (e) {
                /**
                 * Only show error if there is actually an error.
                 * Cancelation errors are empty.
                 */
                if (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while loading business rules data.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /*================================================================================================================
        *                                                  UPSERT
        * ==============================================================================================================*/

    /**
     * Inserts a contact us entry, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IContactUsUpsert} contactUsUpsert
     * @returns {IContactUs | null}
     */
    public static upsertContactUs = createAsyncThunk<
    IContactUs | null,
    {
        upsert : IContactUsUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_CONTACT_US',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const contactUsData = state.masterData.contactUsData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ContactUsHttpService.contactUsUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(contactUsData, res.data, a => a.id === res.data.id);

                if (newList) await setContactUsMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setContactUsData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));
                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the contact us.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a news entry, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {INewsUpsert} newsUpsert
     * @returns {INews | null}
     */
    public static upsertNews = createAsyncThunk<
    INews | null,
    {
        upsert : INewsUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_NEWS',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const newsData = state.masterData.newsData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await NewsHttpService.newsUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(newsData, res.data, a => a.id === res.data.id);

                if (newList) await setNewsMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setNewsData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));
                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the news entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a country, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ICountryUpsert} countryUpsert
     * @returns {ICountry | null}
     */
    public static upsertCountry = createAsyncThunk<
    ICountry | null,
    {
        upsert : ICountryUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_COUNTRY',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const countries = state.masterData.countryData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CountryHttpService.countryUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(countries, res.data, a => a.id === res.data.id);

                if (newList) await setCountryMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setCountryData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));
                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the country.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a country public holiday rule, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ICountryPublicHolidayRuleUpsert} countryUpsert
     * @returns {ICountryPublicHolidayRule | null}
     */
    public static upsertCountryPublicHolidayRule = createAsyncThunk<
    ICountryPublicHolidayRule | null,
    {
        upsert : ICountryPublicHolidayRuleUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_COUNTRY_PUBLIC_HOLIDAY_RULE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const countryPublicHolidayRules = state.masterData.countryPublicHolidayRuleData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CountryPublicHolidayRuleHttpService.CountryPublicHolidayRuleUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(countryPublicHolidayRules, res.data, a => a.id === res.data.id);

                if (newList) await setCountryPublicHolidayRuleMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setCountryPublicHolidayRuleData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));
                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the country public holiday rule.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a division, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IDivisionUpsert} divisionUpsert
     * @returns {IDivision | null}
     */
    public static upsertDivision = createAsyncThunk<
    IDivision | null,
    {
        upsert : IDivisionUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_DIVISION',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const divisions = state.masterData.divisionData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await DivisionHttpService.divisionUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(divisions, res.data, a => a.id === res.data.id);

                if (newList) await setDivisionMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setDivisionData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));
                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the division.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a subdivision, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ISubdivisionUpsert} subdivisionUpsert
     * @returns {ISubdivision | null}
     */
    public static upsertSubdivision = createAsyncThunk<
    ISubdivision | null,
    {
        upsert : ISubdivisionUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_SUBDIVISION',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const subdivisions = state.masterData.subdivisionData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await SubdivisionHttpService.subdivisionUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(subdivisions, res.data, a => a.id === res.data.id);

                if (newList) await setSubdivisionMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setSubdivisionData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the subdivision.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a department, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IDepartmentUpsert} departmentUpsert
     * @returns {IDepartment | null}
     */
    public static upsertDepartment = createAsyncThunk<
    IDepartment | null,
    {
        upsert : IDepartmentUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_DEPARTMENT',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const departments = state.masterData.departmentData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await DepartmentHttpService.departmentUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(departments, res.data, a => a.id === res.data.id);

                if (newList) await setDepartmentMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setDepartmentData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the department.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a production unit, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IProductionUnitUpsert} productionUnitUpsert
     * @returns {IProductionUnit | null}
     */
    public static upsertProductionUnit = createAsyncThunk<
    IProductionUnit | null,
    {
        upsert : IProductionUnitUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_PRODUCTION_UNIT',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const productionUnits = state.masterData.productionUnitData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ProductionUnitHttpService.productionUnitUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(productionUnits, res.data, a => a.id === res.data.id);

                if (newList) await setProductionUnitMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setProductionUnitData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the production unit.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a field, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IFieldUpsert} fieldUpsert
     * @returns {IField | null}
     */
    public static upsertField = createAsyncThunk<
    IField | null,
    {
        upsert : IFieldUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_FIELD',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const fields = state.masterData.fieldData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await FieldHttpService.fieldUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(fields, res.data, a => a.id === res.data.id);

                if (newList) await setFieldMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setFieldData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the field.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a list whether it be a single block or bulk, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IBlockUpsert} blockUpsert
     * @returns {Array<IBlock> | null}
     */
    public static upsertBlock = createAsyncThunk<
    Array<IBlock> | null,
    {
        upsert : IBlockUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_BLOCK',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const blockList = lodash.map(state.masterData.blockData, x => x);

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await BlockHttpService.blockUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElements(blockList, res.data, (a, b) => a.id === b.id);

                if (newList) await setBlockMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setBlockData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entries saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the block.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a crop, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ICropUpsert} cropUpsert
     * @returns {Promise<ICrop | null>}
     */
    public static upsertCrop = createAsyncThunk<
    ICrop | null,
    {
        upsert : ICropUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_CROP',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const crops = state.masterData.cropData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CropHttpService.cropUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(crops, res.data, a => a.id === res.data.id);

                if (newList) await setCropMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setCropData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the crop.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a commodity, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ICommodityUpsert} commodityUpsert
     * @returns {Promise<ICommodity | null>}
     */
    public static upsertCommodity = createAsyncThunk<
    ICommodity | null,
    {
        upsert : ICommodityUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_COMMODITY',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const commodities = state.masterData.commodityData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CommodityHttpService.commodityUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(commodities, res.data, a => a.id === res.data.id);

                if (newList) await setCommodityMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setCommodityData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the commodity.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a variety, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IVarietyUpsert} varietyUpsert
     * @returns {IVariety | null}
     */
    public static upsertVariety = createAsyncThunk<
    IVariety | null,
    {
        upsert : IVarietyUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_VARIETY',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const varieties = state.masterData.varietyData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await VarietyHttpService.varietyUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(varieties, res.data, a => a.id === res.data.id);

                if (newList) await setVarietyMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setVarietyData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the variety.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a project, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IProjectUpsert} projectUpsert
     * @returns {IProject | null}
     */
    public static upsertProject = createAsyncThunk<
    IProject | null,
    {
        upsert : IProjectUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_PROJECT',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const projects = state.masterData.projectData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ProjectHttpService.projectUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(projects, res.data, a => a.id === res.data.id);

                if (newList) await setProjectMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setProjectData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the project.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a activity, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IActivityUpsert} activityUpsert
     * @returns {IActivity | null}
     */
    public static upsertActivity = createAsyncThunk<
    IActivity | null,
    {
        upsert : IActivityUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_ACTIVITY',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const activities = state.masterData.activityData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ActivityHttpService.activityUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(activities, res.data, a => a.id === res.data.id);

                if (newList) await setActivityMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setActivityData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the activity.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a activity type, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IActivityTypeUpsert} activityTypeUpsert
     * @returns {IActivityType | null}
     */
    public static upsertActivityType = createAsyncThunk<
    IActivityType | null,
    {
        upsert : IActivityTypeUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_ACTIVITY_TYPE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const activityTypes = state.masterData.activityTypeData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ActivityTypeHttpService.activityTypeUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(activityTypes, res.data, a => a.id === res.data.id);

                if (newList) await setActivityTypeMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setActivityTypeData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the activity type.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a root stock, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IRootStockUpsert} rootStockUpsert
     * @returns {IRootStock | null}
     */
    public static upsertRootStock = createAsyncThunk<
    IRootStock | null,
    {
        upsert : IRootStockUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_ROOT_STOCK',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const rootStocks = state.masterData.rootStockData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await RootStockHttpService.rootStockUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(rootStocks, res.data, a => a.id === res.data.id);

                if (newList) await setRootStockMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setRootStockData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the root stock.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a clocking method, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IClockingMethodUpsert} clockingMethodUpsert
     * @returns {IClockingMethod | null}
     */
    public static upsertClockingMethod = createAsyncThunk<
    IClockingMethod | null,
    {
        upsert : IClockingMethodUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_CLOCKING_METHOD',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const clockingMethods = state.masterData.clockingMethodData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ClockingMethodHttpService.clockingMethodUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(clockingMethods, res.data, a => a.id === res.data.id);

                if (newList) await setClockingMethodMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setClockingMethodData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the clocking method.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a clocking system, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IClockingSystemUpsert} clockingSystemUpsert
     * @returns {IClockingSystem | null}
     */
    public static upsertClockingSystem = createAsyncThunk<
    IClockingSystem | null,
    {
        upsert : IClockingSystemUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_CLOCKING_SYSTEM',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const clockingSystems = state.masterData.clockingSystemData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ClockingSystemHttpService.clockingSystemUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(clockingSystems, res.data, a => a.id === res.data.id);

                if (newList) await setClockingSystemMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setClockingSystemData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the clocking system.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a employee setup, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IEmployeeSetupUpsert} employeeSetupUpsert
     * @returns {IEmployeeSetup | null}
     */
    public static upsertEmployeeSetup = createAsyncThunk<
    IEmployeeSetup | null,
    {
        upsert : IEmployeeSetupUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_EMPLOYEE_SETUP',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const employeeSetups = state.masterData.employeeSetupData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await EmployeeSetupHttpService.employeeSetupUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(employeeSetups, res.data, a => a.id === res.data.id);

                if (newList) setEmployeeSetupMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setEmployeeSetupData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the employee setup.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a division day of week, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IDivisionDayOfWeekUpsert} divisionDayOfWeekUpsert
     * @returns {IDivisionDayOfWeek | null}
     */
    public static upsertDivisionDayOfWeek = createAsyncThunk<
    IDivisionDayOfWeek | null,
    {
        upsert : IDivisionDayOfWeekUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_DIVISION_DAY_OF_WEEK',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const divisionDayOfWeeks = state.masterData.divisionDayOfWeekData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await DivisionDayOfWeekHttpService.divisionDayOfWeekUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(divisionDayOfWeeks, res.data, a => a.id === res.data.id);

                if (newList) await setDivisionDaysOfWeekMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setDivisionDayOfWeekData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the division day of week.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a employee, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IEmployeeUpsert} employeeUpsert
     * @returns {IEmployee | null}
     */
    public static upsertEmployee = createAsyncThunk<
    IEmployee | null,
    {
        upsert : IEmployeeUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_EMPLOYEE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const employees = state.masterData.employeeData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await EmployeeHttpService.employeeUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(employees, res.data, a => a.id === res.data.id);

                if (newList) await setEmployeeMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setEmployeeData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the employee.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a employee rate, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IEmployeeRateUpsert} EmployeeRateUpsert
     * @returns {IEmployeeRate | null}
     */
    public static upsertEmployeeRate = createAsyncThunk<
    IEmployeeRate | null,
    {
        upsert : IEmployeeRateUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_EMPLOYEE_RATE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const employeeRateHistories = state.masterData.employeeRateData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await EmployeeRateHttpService.EmployeeRateUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(employeeRateHistories, res.data, a => a.id === res.data.id);

                if (newList) await setEmployeeRateMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setEmployeeRateData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the employee rate.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a job type, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IJobTypeUpsert} jobTypeUpsert
     * @returns {IJobType | null}
     */
    public static upsertJobType = createAsyncThunk<
    IJobType | null,
    {
        upsert : IJobTypeUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_JOB_TYPE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const jobTypes = state.masterData.jobTypeData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await JobTypeHttpService.jobTypeUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(jobTypes, res.data, a => a.id === res.data.id);

                if (newList) await setJobTypeMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setJobTypeData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the job type.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a minimum wage history, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IMinimumWageHistoryUpsert} minimumWageHistoryUpsert
     * @returns {IMinimumWageHistory | null}
     */
    public static upsertMinimumWageHistory = createAsyncThunk<
    IMinimumWageHistory | null,
    {
        upsert : IMinimumWageHistoryUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_MINIMUM_WAGE_HISTORY',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const minimumWageHistories = state.masterData.minimumWageHistoryData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await MinimumWageHistoryHttpService.minimumWageHistoryUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(minimumWageHistories, res.data, a => a.id === res.data.id);

                if (newList) await setMinimumWageHistoryMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setMinimumWageHistoryData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the minimum wage history.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a public holiday, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IPublicHolidayUpsert} publicHolidayUpsert
     * @returns {Array<IPublicHoliday> | null}
     */
    public static upsertPublicHoliday = createAsyncThunk<
    Array<IPublicHoliday> | null,
    {
        upsert : IPublicHolidayUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_PUBLIC_HOLIDAY',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const publicHolidays = state.masterData.publicHolidayData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await PublicHolidayHttpService.publicHolidayUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElements(publicHolidays, res.data, (a, b) => a.id === b.id);

                if (newList) await setPublicHolidayMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setPublicHolidayData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the public holiday.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a scan type, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IScanTypeUpsert} scanTypeUpsert
     * @returns {IScanType | null}
     */
    public static upsertScanType = createAsyncThunk<
    IScanType | null,
    {
        upsert : IScanTypeUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_SCAN_TYPE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const scanTypes = state.masterData.scanTypeData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ScanTypeHttpService.scanTypeUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(scanTypes, res.data, a => a.id === res.data.id);

                if (newList) await setScanTypeMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setScanTypeData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the scan type.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a tarif calculation, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ITarifCalculationUpsert} tarifCalculationUpsert
     * @returns {ITarifCalculation | null}
     */
    public static upsertTarifCalculation = createAsyncThunk<
    ITarifCalculation | null,
    {
        upsert : ITarifCalculationUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_TARIF_CALCULATION',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const tarifCalculations = state.masterData.tarifCalculationData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await TarifCalculationHttpService.tarifCalculationUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(tarifCalculations, res.data, a => a.id === res.data.id);

                if (newList) await setTarifCalculationMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setTarifCalculationData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the tarif calculation.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a tarif type, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ITarifTypeUpsert} tarifTypeUpsert
     * @returns {ITarifType | null}
     */
    public static upsertTarifType = createAsyncThunk<
    ITarifType | null,
    {
        upsert : ITarifTypeUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_TARIF_TYPE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const tarifTypes = state.masterData.tarifTypeData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await TarifTypeHttpService.tarifTypeUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(tarifTypes, res.data, a => a.id === res.data.id);

                if (newList) await setTarifTypeMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setTarifTypeData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the tarif type.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts a pay run, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IPayRunUpsert} PayRunUpsert
     * @returns {IPayRun | null}
     */
    public static upsertPayRun = createAsyncThunk<
    IPayRun | null,
    {
        upsert : IPayRunUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_PAY_RUN',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const payRuns = state.masterData.payRunData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await PayRunHttpService.upsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(payRuns, res.data, a => a.id === res.data.id);

                if (newList) await setPayRunMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setPayRunData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the pay run.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts norms, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {Array<INormUpsert>} upsertList
     * @returns {Array<INorm> | null}
     */
    public static upsertNorm = createAsyncThunk<
    Array<INorm> | null,
    {
        upsertList : Array<INormUpsert>;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_NORM',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const normData = state.masterData.normData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await NormHttpService.upsert(params.upsertList, params.type);

                const newList = ArrayHelper.uniqueJoin(res.data, normData, a => a.id);

                await setNormMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setNormData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the norm.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts an activity rate, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IActivityRateUpsert} tarifTypeUpsert
     * @returns {IActivityRate | null}
     */
    public static upsertActivityRate = createAsyncThunk<
    IActivityRate | null,
    {
        upsert : IActivityRateUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_ACTIVITY_RATE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const activityRates = state.masterData.activityRateData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await ActivityRateHttpService.activityRateUpsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(activityRates, res.data, a => a.id === res.data.id);

                if (newList) await setActivityRateMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setActivityRateData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the activity rate.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts company reference, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ICompanyReferenceUpsert} upsert
     * @returns {ICompanyReference | null}
     */
    public static upsertCompanyReference = createAsyncThunk<
    ICompanyReference | null,
    {
        upsert : ICompanyReferenceUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_COMPANY_REFERENCE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const companyReferenceData = state.masterData.companyReferenceData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await CompanyReferenceHttpService.upsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(companyReferenceData, res.data, a => a.id === res.data.id);

                if (newList) await setCompanyReferenceMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setCompanyReferenceData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the company reference.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts business rule, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IBusinessRuleUpsert} upsert
     * @returns {IBusinessRule | null}
     */
    public static upsertBusinessRule = createAsyncThunk<
    IBusinessRule | null,
    {
        upsert : IBusinessRuleUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_BUSINESS_RULE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const businessRuleData = state.masterData.businessRuleData;

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await BusinessRuleHttpService.upsert(params.upsert, params.type);

                const newList = ArrayHelper.upsertElement(businessRuleData, res.data, a => a.id === res.data.id);

                if (newList) await setBusinessRuleMasterDataIndexedDB(newList);

                thunkApi.dispatch(DataActions.setBusinessRuleData(newList));

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the business rule.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts business rule, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IBusinessRuleLineUpsert} upsert
     * @returns {IBusinessRule | null}
     */
    public static upsertBusinessRuleLine = createAsyncThunk<
    IBusinessRule | null,
    {
        upsert : IBusinessRuleLineUpsert;
        type : 'Add' | 'Edit';
    },
    ThunkApi>(
        'MASTER_DATA_UPSERT_BUSINESS_RULE_LINE',
        async (params, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const businessRuleData = state.masterData.businessRuleData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));

                const res = await BusinessRuleLineHttpService.upsert(params.upsert, params.type);

                const businessRule = businessRuleData.find(x => x.id === res.data.businessRuleId);

                if (businessRule) {
                    const updatedLines = ArrayHelper.upsertElement(businessRule.businessRuleLines, res.data, a => a.id === res.data.id);
                    const updatedBusinessRule : IBusinessRule = {
                        ...businessRule,
                        businessRuleLines: updatedLines ?? businessRule.businessRuleLines,
                    };
                    const newList = ArrayHelper.upsertElement(businessRuleData, updatedBusinessRule, a => a.id === businessRule.id);

                    if (newList) await setBusinessRuleMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setBusinessRuleData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));
    
                    return updatedBusinessRule;
                } else {
                    thunkApi.dispatch(GeneralThunk.showWarningSnackbar('Entry saved successfully but failed to update local state. Please to a refresh of the page.'));
                    return null;
                }
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the business rule line.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /*================================================================================================================
        *                                                  DELETE
        * ==============================================================================================================*/

    /**
     * Deletes a contact us entry, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} contactUsId
     * @returns {boolean}
    */
    public static deleteContactUs = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_CONTACT_US',
        async (contactUsId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await ContactUsHttpService.contactUsDelete(contactUsId);

                const contactUsData : Array<IContactUs> = thunkApi.getState().masterData.contactUsData ?? [];
                const deletedContactUs = contactUsData.find(x => x.id === contactUsId);

                if (deletedContactUs) {
                    const updatedContactUs : IContactUs = {
                        ...deletedContactUs,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(contactUsData, updatedContactUs, a => a.id === contactUsId);

                    if (newList) await setContactUsMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setContactUsData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting contact us entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a news entry, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} newsId
     * @returns {boolean}
    */
    public static deleteNews = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_NEWS',
        async (newsId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await NewsHttpService.newsDelete(newsId);

                const newsData : Array<INews> = thunkApi.getState().masterData.newsData ?? [];
                const deletedNews = newsData.find(x => x.id === newsId);

                if (deletedNews) {
                    const updatedNews : INews = {
                        ...deletedNews,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(newsData, updatedNews, a => a.id === newsId);

                    if (newList) await setNewsMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setNewsData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting news entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a country, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} countryId
     * @returns {boolean}
    */
    public static deleteCountry = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_COUNTRY',
        async (countryId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await CountryHttpService.countryDelete(countryId);

                const countries : Array<ICountry> = thunkApi.getState().masterData.countryData ?? [];
                const deletedCountry = countries.find(x => x.id === countryId);

                if (deletedCountry) {
                    const updatedCountry : ICountry = {
                        ...deletedCountry,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(countries, updatedCountry, a => a.id === countryId);

                    if (newList) await setCountryMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setCountryData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting country.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a country public holiday rule, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
    */
    public static deleteCountryPublicHolidayRule = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_COUNTRY_PUBLIC_HOLIDAY_RULE',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await CountryPublicHolidayRuleHttpService.CountryPublicHolidayRuleDelete(id);

                const countryPublicHolidayRules : Array<ICountryPublicHolidayRule> = thunkApi.getState().masterData.countryPublicHolidayRuleData ?? [];
                const deletedCountryPublicHolidayRule = countryPublicHolidayRules.find(x => x.id === id);

                if (deletedCountryPublicHolidayRule) {
                    const updatedCountryPublicHolidayRule : ICountryPublicHolidayRule = {
                        ...deletedCountryPublicHolidayRule,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(countryPublicHolidayRules, updatedCountryPublicHolidayRule, a => a.id === id);

                    if (newList) await setCountryPublicHolidayRuleMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setCountryPublicHolidayRuleData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting country public holiday rule.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a division, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} divisionId
     * @returns {boolean}
    */
    public static deleteDivision = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_DIVISION',
        async (divisionId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DivisionHttpService.divisionDelete(divisionId);

                const divisions : Array<IDivision> = thunkApi.getState().masterData.divisionData ?? [];
                const deletedDivision = divisions.find(x => x.id === divisionId);

                if (deletedDivision) {
                    const updatedDivision : IDivision = {
                        ...deletedDivision,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(divisions, updatedDivision, a => a.id === divisionId);

                    if (newList) await setDivisionMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setDivisionData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting division.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a subdivision, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} subdivisionId
     * @returns {boolean}
    */
    public static deleteSubdivision = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_SUBDIVISION',
        async (subdivisionId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await SubdivisionHttpService.subdivisionDelete(subdivisionId);

                const subdivisions : Array<ISubdivision> = thunkApi.getState().masterData.subdivisionData ?? [];
                const deletedSubdivision = subdivisions.find(x => x.id === subdivisionId);

                if (deletedSubdivision) {
                    const updatedSubdivision : ISubdivision = {
                        ...deletedSubdivision,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(subdivisions, updatedSubdivision, a => a.id === subdivisionId);

                    if (newList) await setSubdivisionMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setSubdivisionData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting subdivision.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a department, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} departmentId
     * @returns {boolean}
     */
    public static deleteDepartment = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_DEPARTMENT',
        async (departmentId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DepartmentHttpService.departmentDelete(departmentId);

                const departments : Array<IDepartment> = thunkApi.getState().masterData.departmentData ?? [];
                const deletedDepartment = departments.find(x => x.id === departmentId);

                if (deletedDepartment) {
                    const updatedDepartment : IDepartment = {
                        ...deletedDepartment,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(departments, updatedDepartment, a => a.id === departmentId);

                    if (newList) await setDepartmentMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setDepartmentData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting department.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a project, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} projectId
     * @returns {boolean}
     */
    public static deleteProject = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_PROJECT',
        async (projectId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await ProjectHttpService.projectDelete(projectId);

                const projects : Array<IProject> = thunkApi.getState().masterData.projectData ?? [];
                const deletedProject = projects.find(x => x.id === projectId);

                if (deletedProject) {
                    const updatedProject : IProject = {
                        ...deletedProject, 
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(projects, updatedProject, a => a.id === projectId);

                    if (newList) await setProjectMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setProjectData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting project.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a production unit, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} productionUnitId
     * @returns {boolean}
     */
    public static deleteProductionUnit = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_PRODUCTION_UNIT',
        async (productionUnitId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await ProductionUnitHttpService.productionUnitDelete(productionUnitId);

                const productionUnits : Array<IProductionUnit> = thunkApi.getState().masterData.productionUnitData ?? [];
                const deletedProductionUnit = productionUnits.find(x => x.id === productionUnitId);

                if (deletedProductionUnit) {
                    const updatedProductionUnit : IProductionUnit = {
                        ...deletedProductionUnit,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(productionUnits, updatedProductionUnit, a => a.id === productionUnitId);

                    if (newList) await setProductionUnitMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setProductionUnitData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting production unit.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a field, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} fieldId
     * @returns {boolean}
     */
    public static deleteField = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_FIELD',
        async (fieldId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await FieldHttpService.fieldDelete(fieldId);

                const fields : Array<IField> = thunkApi.getState().masterData.fieldData ?? [];
                const deletedField = fields.find(x => x.id === fieldId);

                if (deletedField) {
                    const updatedField : IField = {
                        ...deletedField,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(fields, updatedField, a => a.id === fieldId);

                    if (newList) await setFieldMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setFieldData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting field.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a block, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} blockId
     * @returns {boolean}
     */
    public static deleteBlock = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_BLOCK',
        async (blockId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await BlockHttpService.blockDelete(blockId);

                const blocks : Array<IBlock> = thunkApi.getState().masterData.blockData ?? [];
                const deletedBlock = blocks.find(x => x.id === blockId);

                if (deletedBlock) {
                    const updatedBlock : IBlock = {
                        ...deletedBlock, 
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(blocks, updatedBlock, a => a.id === blockId);

                    if (newList) await setBlockMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setBlockData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting block.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a crop, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} cropId
     * @returns {boolean}
     */
    public static deleteCrop = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_Crop',
        async (CropId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await CropHttpService.cropDelete(CropId);

                const crops : Array<ICrop> = thunkApi.getState().masterData.cropData ?? [];
                const deletedCrop = crops.find(x => x.id === CropId);

                if (deletedCrop) {
                    const updatedCrop : ICrop = {
                        ...deletedCrop,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(crops, updatedCrop, a => a.id === CropId);
                    
                    if (newList) await setCropMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setCropData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting crop.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a commodity, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} commodityId
     * @returns {boolean}
     */
    public static deleteCommodity = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_COMMODITY',
        async (commodityId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await CommodityHttpService.commodityDelete(commodityId);

                const commodities : Array<ICommodity> = thunkApi.getState().masterData.commodityData ?? [];
                const deletedCommodity = commodities.find(x => x.id === commodityId);

                if (deletedCommodity) {
                    const updatedCommodity : ICommodity = {
                        ...deletedCommodity,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(commodities, updatedCommodity, a => a.id === commodityId);
                    
                    if (newList) await setCommodityMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setCommodityData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting commodity.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a variety, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} varietyId
     * @returns {boolean}
     */
    public static deleteVariety = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_VARIETY',
        async (varietyId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await VarietyHttpService.varietyDelete(varietyId);

                const varieties : Array<IVariety> = thunkApi.getState().masterData.varietyData ?? [];
                const deletedVariety = varieties.find(x => x.id === varietyId);

                if (deletedVariety) {
                    const updatedVariety : IVariety = {
                        ...deletedVariety,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(varieties, updatedVariety, a => a.id === varietyId);

                    if (newList) await setVarietyMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setVarietyData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting variety.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a activity, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} activityId
     * @returns {boolean}
     */
    public static deleteActivity = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_ACTIVITY',
        async (activityId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await ActivityHttpService.activityDelete(activityId);

                const activities : Array<IActivity> = thunkApi.getState().masterData.activityData ?? [];
                const deletedActivity = activities.find(x => x.id === activityId);

                if (deletedActivity) {
                    const updatedActivity : IActivity = {
                        ...deletedActivity,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(activities, updatedActivity, a => a.id === activityId);

                    if (newList) await setActivityMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setActivityData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting activity.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a activity type, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} activityTypeId
     * @returns {boolean}
     */
    public static deleteActivityType = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_ACTIVITY_TYPE',
        async (activityTypeId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await ActivityTypeHttpService.activityTypeDelete(activityTypeId);

                const activityTypes : Array<IActivityType> = thunkApi.getState().masterData.activityTypeData ?? [];
                const deletedActivityType = activityTypes.find(x => x.id === activityTypeId);

                if (deletedActivityType) {
                    const updatedActivityType : IActivityType = {
                        ...deletedActivityType,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(activityTypes, updatedActivityType, a => a.id === activityTypeId);

                    if (newList) await setActivityTypeMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setActivityTypeData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting activity type.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a root stock, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} rootStockId
     * @returns {boolean}
     */
    public static deleteRootStock = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_ROOT_STOCK',
        async (rootStockId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await RootStockHttpService.rootStockDelete(rootStockId);

                const rootStocks : Array<IRootStock> = thunkApi.getState().masterData.rootStockData ?? [];
                const deletedRootStock = rootStocks.find(x => x.id === rootStockId);

                if (deletedRootStock) {
                    const updatedRootStock : IRootStock = {
                        ...deletedRootStock,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(rootStocks, updatedRootStock, a => a.id === rootStockId);

                    if (newList) await setRootStockMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setRootStockData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting root stock.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a clocking method, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteClockingMethod = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_CLOCKING_METHOD',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await ClockingMethodHttpService.clockingMethodDelete(id);

                const clockingMethods : Array<IClockingMethod> = thunkApi.getState().masterData.clockingMethodData ?? [];
                const deletedClockingMethod = clockingMethods.find(x => x.id === id);

                if (deletedClockingMethod) {
                    const updatedClockingMethod : IClockingMethod = {
                        ...deletedClockingMethod,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(clockingMethods, updatedClockingMethod, a => a.id === id);

                    if (newList) await setClockingMethodMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setClockingMethodData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting clocking method.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a clocking system, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteClockingSystem = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_CLOCKING_SYSTEM',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await ClockingSystemHttpService.clockingSystemDelete(id);

                const clockingSystems : Array<IClockingSystem> = thunkApi.getState().masterData.clockingSystemData ?? [];
                const deletedClockingSystem = clockingSystems.find(x => x.id === id);

                if (deletedClockingSystem) {
                    const updatedClockingSystem : IClockingSystem = {
                        ...deletedClockingSystem,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(clockingSystems, updatedClockingSystem, a => a.id === id);

                    if (newList) await setClockingSystemMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setClockingSystemData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting clocking system.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a employee setup, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteEmployeeSetup = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_EMPLOYEE_SETUP',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await EmployeeSetupHttpService.employeeSetupDelete(id);

                const employeeSetups : Array<IEmployeeSetup> = thunkApi.getState().masterData.employeeSetupData ?? [];
                const deletedEmployeeSetup = employeeSetups.find(x => x.id === id);

                if (deletedEmployeeSetup) {
                    const updatedEmployeeSetup : IEmployeeSetup = {
                        ...deletedEmployeeSetup,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(employeeSetups, updatedEmployeeSetup, a => a.id === id);

                    if (newList) await setEmployeeSetupMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setEmployeeSetupData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting employee setup.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a division day of week, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteDivisionDayOfWeek = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_DIVISION_DAY_OF_WEEK',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DivisionDayOfWeekHttpService.divisionDayOfWeekDelete(id);

                const divisionDayOfWeeks : Array<IDivisionDayOfWeek> = thunkApi.getState().masterData.divisionDayOfWeekData ?? [];
                const deletedDivisionDayOfWeek = divisionDayOfWeeks.find(x => x.id === id);

                if (deletedDivisionDayOfWeek) {
                    const updatedDivisionDayOfWeek : IDivisionDayOfWeek = {
                        ...deletedDivisionDayOfWeek,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(divisionDayOfWeeks, updatedDivisionDayOfWeek, a => a.id === id);

                    if (newList) await setDivisionDaysOfWeekMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setDivisionDayOfWeekData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting division day of week.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a employee, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteEmployee = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_EMPLOYEE',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await EmployeeHttpService.employeeDelete(id);

                const employees : Array<IEmployee> = thunkApi.getState().masterData.employeeData ?? [];
                const deletedEmployee = employees.find(x => x.id === id);

                if (deletedEmployee) {
                    const updatedEmployee : IEmployee = {
                        ...deletedEmployee,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(employees, updatedEmployee, a => a.id === id);

                    if (newList) await setEmployeeMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setEmployeeData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting employee.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a employee rate, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteEmployeeRate = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_EMPLOYEE_RATE',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await EmployeeRateHttpService.EmployeeRateDelete(id);

                const employeeRateHistories : Array<IEmployeeRate> = thunkApi.getState().masterData.employeeRateData ?? [];
                const deletedEmployeeRate = employeeRateHistories.find(x => x.id === id);

                if (deletedEmployeeRate) {
                    const updatedEmployeeRate : IEmployeeRate = {
                        ...deletedEmployeeRate,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(employeeRateHistories, updatedEmployeeRate, a => a.id === id);

                    if (newList) await setEmployeeRateMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setEmployeeRateData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting employee rate.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a job type, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteJobType = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_JOB_TYPE',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await JobTypeHttpService.jobTypeDelete(id);

                const jobTypes : Array<IJobType> = thunkApi.getState().masterData.jobTypeData ?? [];
                const deletedJobType = jobTypes.find(x => x.id === id);

                if (deletedJobType) {
                    const updatedJobType : IJobType = {
                        ...deletedJobType,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(jobTypes, updatedJobType, a => a.id === id);

                    if (newList) await setJobTypeMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setJobTypeData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting job type.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a minimum wage history, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteMinimumWageHistory = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_MINIMUM_WAGE_HISTORY',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await MinimumWageHistoryHttpService.minimumWageHistoryDelete(id);

                const minimumWageHistorys : Array<IMinimumWageHistory> = thunkApi.getState().masterData.minimumWageHistoryData ?? [];
                const deletedMinimumWageHistory = minimumWageHistorys.find(x => x.id === id);

                if (deletedMinimumWageHistory) {
                    const updatedMinimumWageHistory : IMinimumWageHistory = {
                        ...deletedMinimumWageHistory,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(minimumWageHistorys, updatedMinimumWageHistory, a => a.id === id);

                    if (newList) await setMinimumWageHistoryMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setMinimumWageHistoryData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting minimum wage history.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a public holiday, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deletePublicHoliday = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_PUBLIC_HOLIDAY',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await PublicHolidayHttpService.publicHolidayDelete(id);

                const publicHolidays : Array<IPublicHoliday> = thunkApi.getState().masterData.publicHolidayData ?? [];
                const deletedPublicHoliday = publicHolidays.find(x => x.id === id);

                if (deletedPublicHoliday) {
                    const updatedPublicHoliday : IPublicHoliday = {
                        ...deletedPublicHoliday,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(publicHolidays, updatedPublicHoliday, a => a.id === id);

                    if (newList) await setPublicHolidayMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setPublicHolidayData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting public holiday.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a scan type, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteScanType = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_SCAN_TYPE',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await ScanTypeHttpService.scanTypeDelete(id);

                const scanTypes : Array<IScanType> = thunkApi.getState().masterData.scanTypeData ?? [];
                const deletedScanType = scanTypes.find(x => x.id === id);

                if (deletedScanType) {
                    const updatedScanType : IScanType = {
                        ...deletedScanType,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(scanTypes, updatedScanType, a => a.id === id);

                    if (newList) await setScanTypeMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setScanTypeData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting scan type.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a tarif calculation, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteTarifCalculation = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_TARIF_CALCULATION',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await TarifCalculationHttpService.tarifCalculationDelete(id);

                const tarifCalculations : Array<ITarifCalculation> = thunkApi.getState().masterData.tarifCalculationData ?? [];
                const deletedTarifCalculation = tarifCalculations.find(x => x.id === id);

                if (deletedTarifCalculation) {
                    const updatedTarifCalculation : ITarifCalculation = {
                        ...deletedTarifCalculation,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(tarifCalculations, updatedTarifCalculation, a => a.id === id);

                    if (newList) await setTarifCalculationMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setTarifCalculationData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting tarif calculation.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a tarif type, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteTarifType = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_TARIF_TYPE',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await TarifTypeHttpService.tarifTypeDelete(id);

                const tarifTypes : Array<ITarifType> = thunkApi.getState().masterData.tarifTypeData ?? [];
                const deletedTarifType = tarifTypes.find(x => x.id === id);

                if (deletedTarifType) {
                    const updatedTarifType : ITarifType = {
                        ...deletedTarifType,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(tarifTypes, updatedTarifType, a => a.id === id);

                    if (newList) await setTarifTypeMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setTarifTypeData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting tarif type.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a pay run, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deletePayRun = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_PAY_RUN',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await PayRunHttpService.delete(id);

                const payRuns : Array<IPayRun> = thunkApi.getState().masterData.payRunData ?? [];
                const deletedPayRun = payRuns.find(x => x.id === id);

                if (deletedPayRun) {
                    const updatedPayRun : IPayRun = {
                        ...deletedPayRun,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(payRuns, updatedPayRun, a => a.id === id);

                    if (newList) await setPayRunMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setPayRunData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting pay run.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a pay run, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteNorm = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_NORM',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await NormHttpService.delete(id);

                const normData : Array<INorm> = thunkApi.getState().masterData.normData ?? [];
                const deletedNorm = normData.find(x => x.id === id);

                if (deletedNorm) {
                    const updatedNorm : INorm = {
                        ...deletedNorm,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(normData, updatedNorm, a => a.id === id);

                    if (newList) await setNormMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setNormData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting norm.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

        /**
     * Deletes a activity rate, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
        public static deleteActivityRate = createAsyncThunk<
        boolean,
        number,
        ThunkApi>(
            'MASTER_DATA_DELETE_ACTIVITY_RATE',
            async (id, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
                    
                    await ActivityRateHttpService.activityRateDelete(id);
    
                    const activityRates : Array<IActivityRate> = thunkApi.getState().masterData.activityRateData ?? [];
                    const deletedActivityRate = activityRates.find(x => x.id === id);
    
                    if (deletedActivityRate) {
                        const updatedActivityRate : IActivityRate = {
                            ...deletedActivityRate,
                            isActive: false,
                        };
                        const newList = ArrayHelper.upsertElement(activityRates, updatedActivityRate, a => a.id === id);
    
                        if (newList) await setActivityRateMasterDataIndexedDB(newList);
    
                        thunkApi.dispatch(DataActions.setActivityRateData(newList));
                    }
    
                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                    return true;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting activity rate.', e }));
                    return false;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Deletes a company reference, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteCompanyReference = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_COMPANY_REFERENCE',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await CompanyReferenceHttpService.delete(id);

                const companyReferenceData : Array<ICompanyReference> = thunkApi.getState().masterData.companyReferenceData ?? [];
                const deletedCompanyReference = companyReferenceData.find(x => x.id === id);

                if (deletedCompanyReference) {
                    const updatedCompanyReference : ICompanyReference = {
                        ...deletedCompanyReference,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(companyReferenceData, updatedCompanyReference, a => a.id === id);

                    if (newList) await setCompanyReferenceMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setCompanyReferenceData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting company reference.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a business rule, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} id
     * @returns {boolean}
     */
    public static deleteBusinessRule = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_BUSINESS_RULE',
        async (id, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await BusinessRuleHttpService.delete(id);

                const businessRuleData : Array<IBusinessRule> = thunkApi.getState().masterData.businessRuleData ?? [];
                const deletedBusinessRule = businessRuleData.find(x => x.id === id);

                if (deletedBusinessRule) {
                    const updatedBusinessRule : IBusinessRule = {
                        ...deletedBusinessRule,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(businessRuleData, updatedBusinessRule, a => a.id === id);

                    if (newList) await setBusinessRuleMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setBusinessRuleData(newList));
                }

                thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting business rule.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a business rule line, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {IBusinessRuleLine} businessRuleLine
     * @returns {boolean}
     */
    public static deleteBusinessRuleLine = createAsyncThunk<
    boolean,
    IBusinessRuleLine,
    ThunkApi>(
        'MASTER_DATA_DELETE_BUSINESS_RULE_LINE',
        async (businessRuleLine, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await BusinessRuleLineHttpService.delete(businessRuleLine.id);

                const businessRuleData : Array<IBusinessRule> = thunkApi.getState().masterData.businessRuleData ?? [];
                const businessRuleToUpdate = businessRuleData.find(x => x.id === businessRuleLine.businessRuleId);
                const deletedBusinessRuleLine = businessRuleToUpdate?.businessRuleLines.find(x => x.id === businessRuleLine.id);

                if (businessRuleToUpdate && deletedBusinessRuleLine) {
                    const updatedBusinessRuleLine : IBusinessRuleLine = {
                        ...deletedBusinessRuleLine,
                        isActive: false,
                    };

                    const updatedLines = ArrayHelper.upsertElement(businessRuleToUpdate.businessRuleLines, updatedBusinessRuleLine, a => a.id === updatedBusinessRuleLine.id);
                    const updatedBusinessRule : IBusinessRule = {
                        ...businessRuleToUpdate,
                        businessRuleLines: updatedLines ?? businessRuleToUpdate.businessRuleLines,
                    };
                    const newList = ArrayHelper.upsertElement(businessRuleData, updatedBusinessRule, a => a.id === updatedBusinessRule.id);

                    if (newList) await setBusinessRuleMasterDataIndexedDB(newList);

                    thunkApi.dispatch(DataActions.setBusinessRuleData(newList));

                    thunkApi.dispatch(GeneralThunk.showSuccessSnackbar('Entry saved successfully.'));
    
                    return true;
                } else {
                    thunkApi.dispatch(GeneralThunk.showWarningSnackbar('Entry saved successfully but failed to update local state. Please to a refresh of the page.'));
                    return false;
                }
            } catch (e) {
                thunkApi.dispatch(GeneralThunk.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting business rule line.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );
}
